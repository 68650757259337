import React from "react"
import LeftProject from "./leftProject"
import RightProject from "./rightProject"
import { useStaticQuery, graphql } from "gatsby"

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      chatbro: file(relativePath: { eq: "projects/chatbro.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      budgetapp: file(relativePath: { eq: "projects/budgetapp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      trelloclone: file(relativePath: { eq: "projects/trelloclone.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const projectsData = {
    budgetapp: {
      num: "03",
      name: "Budget App",
      img: data.budgetapp.childImageSharp.fluid,
      desc:
        "This app is a budget managment app. As a user you will have to register and login to your user. Then you will have the option to start inputing your incomes and expanses using the buttons on the right. You can also delete certain inputs you entered by clicking on them. Once you are done you can press Save in order to persist the data into the database.",
      skills: ["react", "nodejs", "mongoDB", "html5", "css"],
      linkWebsite: "https://react-budget-omri.herokuapp.com/",
      linkCode: "https://github.com/Gladiotus/React-Budget",
    },
    trelloclone: {
      num: "01",
      name: "TeamWork",
      img: data.trelloclone.childImageSharp.fluid,
      desc:
        "This app is a fully functional Trello clone. You can register as an admin or as a regular employee. The admin is the team leader and he can create new project boards for his team and accept employees requests to join the team. Employees need to find their team and request to join. Then the entire team can view and update the project boards.",
      skills: ["react", "nodejs", "mongoDB", "html5", "css"],
      linkWebsite: "https://team-work-react.herokuapp.com/",
      linkCode: "https://github.com/Gladiotus/TeamWork",
    },
    chatbro: {
      num: "02",
      name: "Chat Bro",
      img: data.chatbro.childImageSharp.fluid,
      desc:
        "This app is a Socket.io based real time chat room app. Any user can enter his name, choose the room he wants to join and be a part of the conversation in that room",
      skills: ["html5", "css", "js", "nodejs"],
      linkWebsite: "https://chat-bro.herokuapp.com/",
      linkCode: "https://github.com/Gladiotus/Chat-Bro",
    },
  }

  return (
    <div className="projects">
      <h2 id="projects">My Projects</h2>
      <div className="underline"></div>
      <LeftProject {...projectsData.trelloclone} />
      <RightProject {...projectsData.chatbro} />
      <LeftProject {...projectsData.budgetapp} />
    </div>
  )
}

export default Projects
