import React from "react"
import CssIcon from "./skills/CssIcon"
import MongoIcon from "./skills/MongoIcon"
import NodeIcon from "./skills/NodeIcon"
import PostgresIcon from "./skills/PostgresIcon"
import SassIcon from "./skills/SassIcon"
import ReactIcon from "./skills/ReactIcon"
import JavascriptIcon from "./skills/JavascriptIcon"
import HtmlIcon from "./skills/HtmlIcon"

const description = props => {
  const generateSkills = () => {
    const skillsMap = {
      css: <CssIcon key="css" />,
      mongoDB: <MongoIcon key="mongo" />,
      nodejs: <NodeIcon key="node" />,
      postgres: <PostgresIcon key="postgres" />,
      sass: <SassIcon key="sass" />,
      react: <ReactIcon key="react" />,
      html5: <HtmlIcon key="html5" />,
      js: <JavascriptIcon key="js" />,
    }
    const skills = Array.from(props.skills).map(item => skillsMap[item])
    return skills
  }
  const onButtonClick = link => {
    window.open(link)
  }
  return (
    <div className="description">
      <h3 className="project-num">{props.num}</h3>
      <h1 className="project-name">{props.name}</h1>
      <p>{props.desc}</p>
      <div className="project-skills">
        <h4>Skills</h4>
        {generateSkills()}
      </div>
      <div className="project-links">
        <button
          className="project-links-website"
          onClick={() => onButtonClick(props.linkWebsite)}
        >
          Website
        </button>
        <button
          className="project-links-code"
          onClick={() => onButtonClick(props.linkCode)}
        >
          Code
        </button>
      </div>
    </div>
  )
}

export default description
