import React, { useState, useEffect } from "react"
import Header from "../components/header"
import Hero from "../components/hero"
import Projects from "../components/projects"
import Contact from "../components/contact"
import SEO from "../components/SEO"
import "fontsource-raleway"
import "../styles/styles.scss"
import "../styles/kepler.css"

const IndexPage = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  let flag = false
  useEffect(() => {
    window.addEventListener("click", mobileMenuClick)
  }, [])
  const mobileMenuClick = e => {
    const target = e.target.closest(".mobile-nav")
    if (flag) {
      setMenuOpen(false)
      flag = false
    } else if (target) {
      setMenuOpen(true)
      flag = true
    }
  }

  return (
    <div className="container">
      <SEO title="Omri Yoffe's Portfolio" />
      <Header isMenuOpen={menuOpen} />
      <Hero />
      <Projects />
      <Contact />
    </div>
  )
}

export default IndexPage
