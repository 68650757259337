import React from "react"
import Links from "./links"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero2: file(relativePath: { eq: "hero2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div id="about" className="hero">
      <div className="inner-hero">
        <div className="about">
          <h1>
            Hey,
            <br /> <em>I'm</em> Omri Yoffe
            <div className="underline"></div>
          </h1>

          <h4>Full Stack Web Developer</h4>
          <p className="about-desktop">
            I am a passionate and inquisitive developer with an ambition to
            constantly learn new technologies, I am studying Computer Science at
            the Academic Colege of Tel Aviv-Yaffo and looking for a student
            position as a developer.
          </p>
          <div className="about-desktop">
            <Links />
          </div>
        </div>
        <p className="about-mobile">
          I am a passionate and inquisitive developer with an ambition to
          constantly learn new technologies, I am studying Computer Science at
          the Academic Colege of Tel Aviv-Yaffo and looking for a student
          position as a developer.
        </p>
        <div className="about-mobile">
          <Links />
        </div>
        <div className="image image-desktop">
          <Img fluid={data.hero.childImageSharp.fluid} />
        </div>
        <div className="image image-mobile">
          <Img fluid={data.hero2.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  )
}

export default Hero
