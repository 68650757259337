import React from "react"

const Resume = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <g id="Group_59" data-name="Group 59" transform="translate(-706 -484)">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z"
          transform="translate(706 484)"
          fill="#62a8ac"
        />
        <g id="notes" transform="translate(718.815 492.995)">
          <g id="Group_61" data-name="Group 61" transform="translate(0 0.005)">
            <g id="Group_60" data-name="Group 60">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M24.879,9.517a2.255,2.255,0,0,0-3.172.276l-2.142,2.535V4.978a2.267,2.267,0,0,0-.7-1.637L16,.626A2.248,2.248,0,0,0,14.449,0H2.258A2.26,2.26,0,0,0,0,2.263V23.433a2.26,2.26,0,0,0,2.258,2.258h15.05a2.26,2.26,0,0,0,2.258-2.258V19.321L25.158,12.7A2.256,2.256,0,0,0,24.879,9.517ZM15.05,1.8c3.011,2.86,2.8,2.651,2.853,2.725H15.05Zm3.01,21.638a.753.753,0,0,1-.753.753H2.258a.753.753,0,0,1-.753-.753V2.263a.753.753,0,0,1,.753-.753H13.545V5.273a.753.753,0,0,0,.753.753H18.06v8.084l-3.2,3.786a2.269,2.269,0,0,0-.466.927l-.7,2.928a.753.753,0,0,0,1.031.865l2.762-1.2a2.269,2.269,0,0,0,.572-.359Zm-1.79-4.875,1.153.967-.256.3a.755.755,0,0,1-.277.207l-1.381.6.35-1.464a.754.754,0,0,1,.154-.307Zm2.124-.183-1.152-.967L21.4,12.487l1.15.965Zm5.612-6.647-.484.573-1.149-.964.486-.575a.75.75,0,0,1,1.148.966Z"
                transform="translate(0 -0.005)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_63"
            data-name="Group 63"
            transform="translate(3.01 4.52)"
          >
            <g id="Group_62" data-name="Group 62">
              <path
                id="Path_33"
                data-name="Path 33"
                d="M68.276,90H60.751a.753.753,0,0,0,0,1.505h7.525a.753.753,0,1,0,0-1.505Z"
                transform="translate(-59.998 -90.003)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_65"
            data-name="Group 65"
            transform="translate(3.01 9.085)"
          >
            <g id="Group_64" data-name="Group 64">
              <path
                id="Path_34"
                data-name="Path 34"
                d="M72.791,181H60.751a.753.753,0,1,0,0,1.505h12.04a.753.753,0,0,0,0-1.505Z"
                transform="translate(-59.998 -181.001)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_67"
            data-name="Group 67"
            transform="translate(3.01 13.6)"
          >
            <g id="Group_66" data-name="Group 66">
              <path
                id="Path_35"
                data-name="Path 35"
                d="M72.791,271H60.751a.753.753,0,1,0,0,1.505h12.04a.753.753,0,0,0,0-1.505Z"
                transform="translate(-59.998 -271)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_69"
            data-name="Group 69"
            transform="translate(3.01 18.116)"
          >
            <g id="Group_68" data-name="Group 68">
              <path
                id="Path_36"
                data-name="Path 36"
                d="M68.276,361H60.751a.753.753,0,0,0,0,1.505h7.525a.753.753,0,0,0,0-1.505Z"
                transform="translate(-59.998 -360.998)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Resume
