import React from "react"

const CssIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 1771 2499.8"
      viewBox="0 0 1771 2499.8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m1387.8 92.5h-146.9l152.8 165.9v78.9h-314.8v-92.4h152.8l-152.8-165.9v-79h309zm-371.4 0h-147.1l153 165.9v78.9h-314.9v-92.4h152.8l-152.8-165.9v-79h309zm-367.8 3.8h-158.5v144.8h158.5v96.3h-271.3v-337.4h271.3z"
        fill="#131313"
      />
      <path
        d="m161.2 2299.1-161.2-1807.7h1771l-161.4 1807.4-725.2 201z"
        fill="#1572b6"
      />
      <path d="m885.5 2346.2 586-162.5 137.8-1544.5h-723.8z" fill="#33a9dc" />
      <path
        d="m885.5 1294.1h293.4l20.2-227h-313.6v-221.6h556l-5.3 59.5-54.5 611h-496.2z"
        fill="#fff"
      />
      <path
        d="m886.7 1869.9h-1l-246.9-66.7-15.8-176.8h-222.4l31.1 348.1 454.2 126.4h1.3v-231z"
        fill="#ebebeb"
      />
      <path
        d="m1160.1 1506.3-26.7 296.7-247.3 66.7v231l454.5-126 3.3-37.5 38.6-431h-222.4z"
        fill="#fff"
      />
      <path
        d="m886.3 845.5v221.7h-535.4l-4.6-49.8-10.1-112.4-5.3-59.5zm-.8 448.6v221.7h-244.1l-4.3-49.8-10.1-112.4-5.3-59.5h263.7z"
        fill="#ebebeb"
      />
    </svg>
  )
}

export default CssIcon
