import React from "react"

const Linkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <g id="Group_28" data-name="Group 28" transform="translate(-706 -484)">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z"
          transform="translate(706 484)"
          fill="#62a8ac"
        />
        <path
          id="Icon_awesome-phone-alt"
          data-name="Icon awesome-phone-alt"
          d="M22.342,16.251,17.311,14.1a1.078,1.078,0,0,0-1.258.31l-2.228,2.722A16.649,16.649,0,0,1,5.866,9.168L8.588,6.94A1.075,1.075,0,0,0,8.9,5.683L6.742.652A1.085,1.085,0,0,0,5.507.028L.835,1.106A1.078,1.078,0,0,0,0,2.156,20.84,20.84,0,0,0,20.842,23a1.078,1.078,0,0,0,1.051-.835l1.078-4.671a1.091,1.091,0,0,0-.629-1.24Z"
          transform="translate(717.001 495.001)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default Linkedin
