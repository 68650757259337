import React from "react"
import { IoIosMail as MailIcon } from "react-icons/io"
import { ImPhone as PhoneIcon } from "react-icons/im"
import { AiFillLinkedin as LinkIcon } from "react-icons/ai"

const contact = () => {
  return (
    <div id="contact" className="contact">
      <h2>Stay In Touch</h2>
      <div className="underline"></div>
      <div className="contact-info">
        <MailIcon />
        <div className="email-container">
          <h3>Email Address</h3>
          <h4>omriyoffe366@gmail.com</h4>
        </div>
        <PhoneIcon />
        <div className="phone-container">
          <h3>Phone Number</h3>
          <h4>052-3890706</h4>
        </div>
        <LinkIcon />
        <div className="linkedin-container">
          <h3>Linkedin Profile</h3>
          <h4>Omri Yoffe</h4>
        </div>
      </div>
    </div>
  )
}

export default contact
