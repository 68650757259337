import React from "react"

const Linkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <g id="Group_29" data-name="Group 29" transform="translate(-651 -484)">
        <path
          id="Path_15"
          data-name="Path 15"
          d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z"
          transform="translate(651 484)"
          fill="#62a8ac"
        />
        <g
          id="Icon_ionic-ios-mail"
          data-name="Icon ionic-ios-mail"
          transform="translate(658.057 490.27)"
        >
          <path
            id="Path_4"
            data-name="Path 4"
            d="M27.314,10.349l-6.243,6.359a.112.112,0,0,0,0,.162l4.369,4.653a.753.753,0,0,1,0,1.068.756.756,0,0,1-1.068,0l-4.352-4.636a.119.119,0,0,0-.168,0l-1.062,1.079a4.672,4.672,0,0,1-3.33,1.4,4.765,4.765,0,0,1-3.4-1.445L11.04,17.956a.119.119,0,0,0-.168,0L6.52,22.592a.756.756,0,0,1-1.068,0,.753.753,0,0,1,0-1.068l4.369-4.653a.124.124,0,0,0,0-.162L3.572,10.349a.114.114,0,0,0-.2.081V23.155a1.862,1.862,0,0,0,1.857,1.857H25.655a1.862,1.862,0,0,0,1.857-1.857V10.431A.116.116,0,0,0,27.314,10.349Z"
            transform="translate(0 -0.426)"
            fill="#fff"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M15.592,18.5a3.154,3.154,0,0,0,2.269-.952l9.1-9.266a1.824,1.824,0,0,0-1.149-.406H5.375a1.812,1.812,0,0,0-1.149.406l9.1,9.266A3.155,3.155,0,0,0,15.592,18.5Z"
            transform="translate(-0.149)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default Linkedin
