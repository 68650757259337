import React from "react"
import Linkedin from "./icons/Linkedin"
import Phone from "./icons/Phone"
import Mail from "./icons/Mail"
import Resume from "./icons/Resume"
import downloadFile from "../images/Resume_Omri_Yoffe.pdf"

const Links = () => {
  return (
    <div className="links">
      <a href="https://www.linkedin.com/in/omri-yoffe-94856119a/">
        <Linkedin />
        <span>Linkedin</span>
      </a>
      <a href="mailto:omriyoffe366@gmail.com">
        <Mail />
        <span>Email</span>
      </a>
      <a href="tel:0523890706">
        <Phone />
        <span>Phone</span>
      </a>
      <a href={downloadFile} download>
        <Resume />
        <span>Resume</span>
      </a>
    </div>
  )
}

export default Links
