import React from "react"
import Img from "gatsby-image"
import Description from "./description"

const LeftProject = props => {
  return (
    <div className="project project__left">
      <div className="background">
        <div className="image">
          <Img fluid={props.img} />
        </div>
      </div>

      <div className="project-desc desc">
        <Description {...props} />
      </div>
    </div>
  )
}

export default LeftProject
