import React from "react"

const Linkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <g id="Group_30" data-name="Group 30" transform="translate(-596 -484)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="22.5"
          cy="22.5"
          r="22.5"
          transform="translate(596 484)"
          fill="#62a8ac"
        />
        <path
          id="Icon_awesome-linkedin-in"
          data-name="Icon awesome-linkedin-in"
          d="M4.365,19.5H.322V6.481H4.365ZM2.341,4.706A2.352,2.352,0,1,1,4.683,2.342,2.361,2.361,0,0,1,2.341,4.706ZM19.5,19.5H15.462V13.163c0-1.51-.03-3.447-2.1-3.447-2.1,0-2.424,1.641-2.424,3.339V19.5H6.9V6.481h3.877V8.257h.057a4.248,4.248,0,0,1,3.825-2.1c4.092,0,4.844,2.694,4.844,6.194V19.5Z"
          transform="translate(608.75 495)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default Linkedin
